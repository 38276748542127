import React from "react";
import './service.css';

export default function Service(){


    return(
        <div>
            <div>
                <p class="up">



                    
                    Scaling up of Business activities of Farmer Development Center (FDC): 
</p>
</div>
  <img style={{height:"20px" ,marginLeft:"50px",marginTop:"10px"}} src="next.png"/><p class="act" style={{marginLeft:"80px",marginTop:"-27px"}}>Getting Income by sale of quality Seeds, Fertilisers and Pesticides through Farmer Development Centre</p>
  <img style={{height:"20px" ,marginLeft:"50px",marginTop:"-15px"}} src="next.png"/><p class="act"  style={{marginLeft:"80px",marginTop:"-30px"}}>Sale of sprayers, tarpaulins, mulching sheets, other non licensed items with reasonable price.
</p>
<img style={{height:"20px" ,marginLeft:"50px",marginTop:"-15px"}} src="next.png"/><p class="act"style={{marginLeft:"80px",marginTop:"-30px"}}>Sale of Cattle feed and veterinary supplements through Farmer Development Centre.
</p>
<img style={{height:"20px" ,marginLeft:"50px",marginTop:"-15px"}} src="next.png"/><p class="act"style={{marginLeft:"80px",marginTop:"-30px"}}>Also sale of top quality recommended Pesticides through Farmer Development Centre.

</p>
<img style={{height:"20px" ,marginLeft:"50px",marginTop:"-15px"}} src="next.png"/><p class="act"style={{marginLeft:"80px",marginTop:"-30px"}}>Proposed turnover for the financial year is 109 lakhs and net income is 6.5 lakhs with a margin of 6 per cent. 

</p>
<h3 class="ups">Optimum Utilization of CHC- Tractors :</h3>
<p class="act" style={{marginLeft:"60px"}}>Effective utilization of tractors for all agriculture activities and getting an income of 0.18 lakhs(Per month).  Estimated annual income through this activity is 4.32 lakhs (0.36 lakhs per month)
</p>
<h3 class="ups">Marketing of Fuits and Vegetables :
</h3>
<p class="act" style={{marginLeft:"60px"}}>The FPO has to tie up with corporate marketing institutes like big basket, metro cash & carry etc for marketing vegetables and fruits. FPC has a plan to start own collection centre to supply fruits and vegetables to said institutional buyers and also sale to consumers directly. The estimated business in the year is 815 MTS with a turnover of 113 lakhs and get the net income is 3.4 lakhs with a margin of 3 %.
</p>

<h3 class="ups">Sampoorna Project Activities : 
</h3>
<img style={{height:"20px" ,marginLeft:"50px",marginTop:"10px"}} src="next.png"/><p class="act" style={{marginLeft:"80px",marginTop:"-27px"}}>	The FPO is decided to establish its own collection centre.    </p>
  <img style={{height:"20px" ,marginLeft:"50px",marginTop:"-15px"}} src="next.png"/><p class="act"  style={{marginLeft:"80px",marginTop:"-30px"}}>Supporting rural livelihoods 
</p>
<img style={{height:"20px" ,marginLeft:"50px",marginTop:"-15px"}} src="next.png"/><p class="act"style={{marginLeft:"80px",marginTop:"-30px"}}>	Identification and training to rural women entrepreneurs and supporting them for establishing small processing units

</p>
<img style={{height:"20px" ,marginLeft:"50px",marginTop:"-15px"}} src="next.png"/><p class="act"style={{marginLeft:"80px",marginTop:"-30px"}}>	Identification and training of Padimithras.


</p>
<img style={{height:"20px" ,marginLeft:"50px",marginTop:"-15px"}} src="next.png"/><p class="act"style={{marginLeft:"80px",marginTop:"-30px"}}>	Support to dairy farmers by providing quality green fodder saplings and seeds 
</p>
<img style={{height:"20px" ,marginLeft:"50px",marginTop:"-15px"}} src="next.png"/><p class="act"style={{marginLeft:"80px",marginTop:"-30px"}}>	Promoting TMR as an entrepreneur model(2 Entreprenuers)
 
</p>
<img style={{height:"20px" ,marginLeft:"50px",marginTop:"-15px"}} src="next.png"/><p class="act"style={{marginLeft:"80px",marginTop:"-30px"}}>	CHC – Purchase of small tractor.

</p>
<img style={{height:"20px" ,marginLeft:"50px",marginTop:"-15px"}} src="next.png"/><p class="act"style={{marginLeft:"80px",marginTop:"-30px"}}>	Creating awareness regarding Desi cow milk importance on Public health and converting atleast 15 farmers from Crossbred cattle farming to Desi cattle farming.
 
</p>
<img style={{height:"20px" ,marginLeft:"50px",marginTop:"-15px"}} src="next.png"/><p class="act"style={{marginLeft:"80px",marginTop:"-30px"}}>	Ensuring availability of atleast 3 varieties of fodder at the farmer level for better nutrition to the animals.
 
</p>
<img style={{height:"20px" ,marginLeft:"50px",marginTop:"-15px"}} src="next.png"/><p class="act"style={{marginLeft:"80px",marginTop:"-30px"}}> 36 animal health camps.
 
</p>
<h3 class="ups">	Services to Farmers : 
</h3>
<p class="act" style={{marginLeft:"60px"}}>	With the support of APMAS, FPC has been providing technical support to farmers to reduce cost of cultivation and increase productivity.  Also providing financial support to selected farmers under Demo Farmers component in SAMPOORNA project. FPC decided to enhance these services with the support of Aurobindo Pharma Foundation for the next FY 2022-2023.
</p>
<h3 class="ups">Human resources:
</h3>
<p class="act" style={{marginLeft:"60px"}}>	One FPO CEO and One Sales person has been working for business development activities in FPO 

</p>
<h3 class="ups">Input and output  marketing target
</h3>
<h6  class="ups" style={{marginTop:"-10px",
    marginLeft:"190px",fontSize:'22px'
}}>Input Business:
</h6>
<table class="uct">
<tr>
<th>Product</th>
<th>Quarter2</th>
<th>Quarter2</th>
<th>Quarter3</th>
<th>Quarter4</th>
<th>Total Annual Target</th>
</tr>
<tr>
<th></th>
<th>Fins in Lakhs</th>
<th>Fins in Lakhs</th>
<th>Fins in Lakhs</th>
<th>Fins in Lakhs</th>
<th>Fins in Lakhs</th>
</tr>
<tr>
    <td>Fertilizers</td>
    <td></td>
    <td></td>
    <td></td>
    <td></td>
    <td></td>
</tr>
<tr>
<td>DAP</td>
    <td>0.00</td>
    <td>1.25</td>
    <td>1.25</td>
    <td>1.25</td>
    <td>3.75</td>
</tr>
<tr>
<td>Complex</td>
    <td>0.00</td>
    <td>1.20</td>
    <td>1.20</td>
    <td>1.20</td>
    <td>3.60</td>
</tr>
<tr>
<td>Urea</td>
    <td>0.25</td>
    <td>0.25</td>
    <td>0.25</td>
    <td>0.25</td>
    <td>1.01</td>
</tr>
<tr>
<td>Drip fertilizers</td>
    <td>1.15</td>
    <td>1.15</td>
    <td>1.15</td>
    <td>1.15</td>
    <td>4.60</td>
</tr>
<tr>
<td>Total </td>
    <td>1.40</td>
    <td>3.85</td>
    <td>3.85</td>
    <td>3.85</td>
    <td>12.96</td>
</tr>
<tr>
<td>Pesticide</td>
    <td></td>
    <td></td>
    <td></td>
    <td></td>
    <td></td>
</tr>
<tr>
<td>Mango season</td>
    <td></td>
    <td></td>
    <td></td>
    <td>60.0</td>
    <td>60.0</td>
</tr>
<tr>
<td>Other seasons</td>
    <td>5.00</td>
    <td>5.00</td>
    <td>5.00</td>
    <td></td>
    <td>15.00</td>
</tr>
<tr>
<td>        </td>
    <td>5.00</td>
    <td>5.00</td>
    <td>5.00</td>
    <td>60.0</td>
    <td>60.0</td>
</tr>
<tr>
<td>Seeds </td>
    <td></td>
    <td></td>
    <td></td>
    <td></td>
    <td>2.0</td>
</tr>
<tr>
<td>Feed and suppliments</td>
    <td>3.0</td>
    <td>3.00</td>
    <td>3.00</td>
    <td>3.00</td>
    <td>15.20</td>
</tr>
<tr>
<td>Others/ Non License </td>
    <td></td>
    <td></td>
    <td></td>
    <td></td>
    <td></td>
</tr>
<tr>
<td>Tarpaulins, spreyers, Mulching</td>
    <td></td>
    <td></td>
    <td>1.75</td>
    <td></td>
    <td>4.45</td>
</tr>
<tr>
<td>Total </td>
    <td></td>
    <td></td>
    <td></td>
    <td></td>
    <td>109.61</td>
</tr>


</table>

<h6  class="ups" style={{marginTop:"20px",
    marginLeft:"70px",marginTop:"40px"
}}>Output Marketing
</h6>
<h3 class="act" style={{marginLeft:"70px",marginTop:"-50px"}}>Depending on the market demand and interest of the FPC board of directors output marketing will be done on the below agriculture and horticulture products.
</h3>
<h3 style={{marginLeft:"70px",marginTop:"30px"}}>Mango
</h3>
<h3  style={{marginLeft:"70px",marginTop:"30px"}}>Groundnut
</h3>
<h3  style={{marginLeft:"70px",marginTop:"30px"}}>Vegetables</h3>
    
        </div>
    )
}