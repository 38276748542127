import React from "react";
import './photo.css';
export default function Photos(){
    const hide=()=>{
        let button = document.getElementById('dj');
        let pic = document.getElementById('zm');
        let pisc = document.getElementById('zmk');
        let pis = document.getElementById('zms');
        let pism = document.getElementById('zmp');

        if(button.style.display === 'block'){
            button.style.display = 'none';
            pic.style.display = 'flex';
            pisc.style.display = 'flex';
            pis.style.display = 'flex';
            pism.style.display = 'flex';
        }else{
            button.style.display = 'block'
        }
    }
    return(
        <>
        <div class="main">
           
                <h1 class="aih">FY 2023-2024 Activities Photos</h1>
             
                <div >
                <div class="hip">
                    <h2 class="aim">Awards by Lions Club</h2>
                    </div>
                    <div style={{display:"flex",marginTop:'40px'}} class="kop">
                        <img class="pitr" style={{width:"45%",marginLeft:"20px"}} src="alc2324-1.jpg"/>
                        <img class="pitr" style={{marginLeft:"50px",width:"45%"}} src="alc2324-2.jpg"/>
                    </div>
                
                <div class="hip" style={{marginTop:"20px"}}>
                    <h2 class="aim">Piler Board Meeting</h2>
                    </div>
                    <div style={{display:"flex",marginTop:"50px"}} class="kop">
                        <img class="pitr" style={{width:"45%",marginLeft:"20px"}} src="pbm2324-1.jpg"/>
                        <img class="pitr" style={{marginLeft:"50px",width:"45%"}}src="pbm2324-2.jpeg"/>
                    </div>
                    <div class="hip" style={{marginTop:"20px"}}>
                    <h2 class="aim">Kalikiri Board Meeting</h2>
                    </div>
                    <div style={{display:"flex", marginTop:"30px"}} class="kop">
                        <img class="pitr"style={{width:"45%",marginLeft:"220px"}} src="kbm2324-1.jpg"/>
                </div>
                <div class="hip" style={{marginTop:"20px"}}>
                    <h2 class="aim">Animal Health Camps</h2>
                    </div>
                    <div style={{display:"flex",marginTop:"30px"}} class="kop" id="fgh">
                        <img class="pitr"style={{marginLeft:"25px"}} src="ahc2324-1.jpg"/>
                        <img class="pitr" style={{marginLeft:"20px"}}src="ahc2324-2.jpg"/>
                        <img class="pitr" style={{marginLeft:"20px"}}src="ahc2324-3.jpg"/>
                    </div>
                    <div style={{display:"flex", marginTop:"20px"}} class="kop" id="fgh">
                        <img class="pitr" style={{marginLeft:"25px"}} src="ahc2324-4.jpg"/>
                        <img class="pitr" style={{marginLeft:"20px"}}src="ahc2324-5.jpeg"/>
                        <img class="pitr" style={{marginLeft:"20px"}}src="ahc2324-6.jpeg"/>
                    </div>
                    <div style={{display:"flex", marginTop:"20px"}} class="kop" id="fgh">
                        <img class="pitr" style={{marginLeft:"25px"}} src="ahc2324-7.jpeg"/>
                        <img class="pitr" style={{marginLeft:"20px"}}src="ahc2324-8.jpeg"/>
                        <img class="pitr" style={{marginLeft:"20px"}}src="ahc2324-9.jpeg"/>
                    </div>
                    <div style={{display:"flex", marginTop:"20px"}} class="kop" id="fgh" >
                        <img class="pitr" style={{marginLeft:"25px"}} src="ahc2324-10.jpeg"/>
                        <img class="pitr" style={{marginLeft:"20px"}}src="ahc2324-11.jpeg"/>
                        <img class="pitr" style={{marginLeft:"20px"}}src="ahc2324-12.jpeg"/>
                    </div>
                    <div style={{display:"flex", marginTop:"20px"}} class="kop" id="fgh">
                        <img class="pitr" style={{marginLeft:"25px"}} src="ahc2324-13.jpeg"/>
                        <img class="pitr" style={{marginLeft:"20px"}}src="ahc2324-15.jpeg"/>
                        <img class="pitr" style={{marginLeft:"20px"}}src="ahc2324-16.jpeg"/>
                    </div>
                    <div style={{display:"flex", marginTop:"20px"}} class="kop" >
                       
                        <img class="pitr" style={{width:"45%",marginLeft:"20px"}} src="ahc2324-17.jpeg"/>
                        <img class="pitr" style={{marginLeft:"50px",width:"45%"}}src="ahc2324-18.jpeg"/>
                    </div>
                </div>
                <div class="hip" style={{marginTop:"20px"}}>
                    <h2 class="aim" style={{marginLeft:"260px"}}>Convergence with Horticulture:Training in Grafting</h2>
                    </div>
                    <div style={{display:"flex",marginTop:"20px"}} class="kop" id="fgh">
                        <img class="pitr" style={{marginLeft:"25px"}} src="tog2324-1.jpg"/>
                        <img class="pitr" style={{marginLeft:"20px"}}src="tog2324-2.jpg"/>
                        <img class="pitr" style={{marginLeft:"20px"}}src="tog2324-3.jpg"/>
                    </div>
                    <div style={{display:"flex", marginTop:"20px"}} class="kop">
                        <img class="pitr" style={{width:"45%",marginLeft:"20px"}}src="tog2324-4.jpg"/>
                        <img class="pitr" style={{marginLeft:"50px",width:"45%"}}src="tog2324-5.jpg"/>
                    </div>
                </div>
                <div class="hip" style={{marginTop:"20px"}}>
                    <h2 class="aim">Convergence with KVK</h2>
                    </div>
                    <div style={{display:"flex",marginTop:"20px"}} class="kop" id="fgh">
                        <img class="pitr" style={{marginLeft:"25px"}} src="cwk2324-1.jpg"/>
                        <img class="pitr" style={{marginLeft:"20px"}}src="cwk2324-2.jpg"/>
                        <img class="pitr" style={{marginLeft:"20px"}}src="cwk2324-3.jpg"/>
                    </div>
                    <div style={{display:"flex", marginTop:"20px"}} class="kop" id="fgh">
                        <img class="pitr"  style={{marginLeft:"25px"}} src="cwk2324-4.jpg"/>
                        <img class="pitr" style={{marginLeft:"20px"}}src="cwk2324-5.jpg"/>
                        <img class="pitr" style={{marginLeft:"20px"}}src="cwk2324-6.jpg"/>
                    </div>  

                <div class="hip" style={{marginTop:"20px"}}>
                    <h2 class="aim" style={{marginLeft:"320px"}}>Other Government Convergence</h2>
                    </div>
                    <div style={{display:"flex",marginTop:"20px"}} class="kop" id="fgh">
                        <img class="pitr" style={{marginLeft:"25px"}}  src="ogc2324-1.jpg"/>
                        <img class="pitr" style={{marginLeft:"20px"}}src="ogc2324-2.jpg"/>
                        <img class="pitr" style={{marginLeft:"20px"}}src="ogc2324-3.jpeg"/>
                    </div>
           
                <div class="hip" style={{marginTop:'20px'}}>
                    <h2 class="aim">Demo Plots</h2>
                    </div>
                    <div style={{display:"flex",marginTop:"20px"}} class="kop" id="fgh">
                        <img class="pitr"  style={{marginLeft:"25px"}} src="dpl2324-1.jpeg"/>
                        <img class="pitr" style={{marginLeft:"20px"}}src="dpl2324-2.jpeg"/>
                        <img class="pitr" style={{marginLeft:"20px"}}src="dpl2324-3.jpeg"/>
                    </div>
                   
                <div class="hip" style={{marginTop:"20px"}}>
                    <h2 class="aim" style={{marginLeft:"290px"}}>District Level Workshop on Mango</h2>
                    </div>
                    <div style={{display:"flex",marginTop:"20px"}} class="kop" id="fgh">
                        <img class="pitr" style={{marginLeft:"25px"}} src="dlwm2324-1.jpeg"/>
                        <img class="pitr" style={{marginLeft:"20px"}}src="dlwm2324-2.jpeg"/>
                        <img class="pitr" style={{marginLeft:"20px"}}src="dlwm2324-3.jpeg"/>
                    </div>
                    <div style={{display:"flex", marginTop:"20px"}} class="kop">
                        <img class="pitr" style={{marginLeft:"245px",width:"45%"}} src="dlwm2324-4.jpeg"/>
                    </div>  
               
                <div class="hip" style={{marginTop:"20px"}}>
                    <h2 class="aim">FFS</h2>
                    </div>
                    <div style={{display:"flex",marginTop:"20px"}} class="kop" id="fgh">
                        <img class="pitr"style={{marginLeft:"25px"}} src="ffs2324-1.jpeg"/>
                        <img class="pitr" style={{marginLeft:"20px"}}src="ffs2324-2.jpeg"/>
                        <img class="pitr" style={{marginLeft:"20px"}}src="ffs2324-3.jpeg"/>
                    </div>
                    <div style={{display:"flex", marginTop:"20px"}} class="kop">
                        <img class="pitr"  style={{marginLeft:"245px",width:"45%"}}src="ffs2324-4.jpeg"/>
                    </div>  
                
                <div class="hip" style={{marginTop:"20px"}}>
                  
                    <h2 class="aim">Heifers Distribution</h2>
                    </div>
                    <div style={{display:"flex", marginTop:"20px"}} class="kop">
                        <img class="pitr" style={{marginLeft:"245px",width:"45%"}}src="hfd2324-1.jpg"/>
                    </div>  
              

            <div class="hip" style={{marginTop:"20px"}}>
                <h2 class="aim"style={{marginLeft:"290px"}}>Milking Machine Demo and Distribution</h2>
                </div>
                <h3>Demo</h3>
                <div style={{display:"flex", marginTop:"20px"}} class="kop">
                    <img class="pitr" style={{width:"45%",marginLeft:"20px"}} src="mmd2324-1.jpg"/>
                    <img class="pitr" style={{marginLeft:"50px",width:"45%"}}src="mmd2324-2.jpg"/>
                </div>
                <h3>Distribution</h3>
                <div style={{display:"flex", marginTop:"10px"}} class="kop">
                     <img class="pitr" style={{marginLeft:"245px",width:"45%"}}src="mmds2324-1.jpg"/>
                </div>  
            
            <div class="hip" style={{marginTop:"20px"}}>

                <h2 class="aim">Chaff Cutters</h2>
                </div>
                <div style={{display:"flex",marginTop:"20px"}} class="kop">
                    <img class="pitr"  style={{width:"45%",marginLeft:"20px"}}src="ccd2324-1.jpeg"/>
                    <img class="pitr" style={{marginLeft:"50px",width:"45%"}}src="ccd2324-2.jpeg"/>
                </div> 
           
            <div class="hip" style={{marginTop:"20px"}}>
                <h2 class="aim">Mineral Mixture Distribution</h2>
                </div>
                <div style={{display:"flex",marginTop:"20px"}} class="kop" id="fgh">
                    <img class="pitr" style={{marginLeft:"25px"}}src="mimd2324-1.jpeg"/>
                    <img class="pitr" style={{marginLeft:"20px"}}src="mimd2324-2.jpeg"/>
                    <img class="pitr" style={{marginLeft:"20px"}}src="mimd2324-3.jpeg"/>
                </div>
                <div style={{display:"flex", marginTop:"20px"}} class="kop" id="fgh">
                     <img class="pitr" style={{marginLeft:"25px"}}src="mimd2324-4.jpeg"/>
                    <img class="pitr" style={{marginLeft:"20px"}}src="mimd2324-5.jpeg"/>
                    <img class="pitr" style={{marginLeft:"20px"}}src="mimd2324-6.jpeg"/>
                </div>  
           
            <div class="hip" style={{marginTop:"20px"}}>
                <h2 class="aim">Orientation on PMFME & PMEP</h2>
                </div>
                <div style={{display:"flex", marginTop:"20px"}} class="kop" id="fgh">
                    <img class="pitr" style={{marginLeft:"25px"}}src="opp2324-1.jpg"/>
                    <img class="pitr" style={{marginLeft:"20px"}}src="opp2324-2.jpg"/>
                    <img class="pitr" style={{marginLeft:"20px"}}src="opp2324-3.jpg"/>
                </div>
                <div style={{display:"flex", marginTop:"20px"}} class="kop">
                     <img class="pitr" style={{marginLeft:"245px",width:"45%"}} src="opp2324-1.jpg"/>
                </div>  
            
            <div class="hip" style={{marginTop:"20px"}}>
                <h2 class="aim">RO Water Plant</h2>
                </div>
                <h3>Fencing Work</h3>
                <div style={{display:"flex", marginTop:"20px"}} class="kop">
                    <img class="pitr" style={{marginLeft:"245px",width:"45%"}}src="rofw2324-1.jpeg"/>
                </div>
                <h3>RO Plant Servicing</h3>
                <div style={{display:"flex", marginTop:"20px"}} class="kop">
                    <img class="pitr"style={{width:"45%",marginLeft:"20px"}} src="rops2324-1.jpg"/>
                    <img class="pitr" style={{marginLeft:"50px",width:"45%"}}src="rops2324-2.jpg"/>
                </div>  
         
            <div class="hip" style={{marginTop:"20px"}}>
                <h2 class="aim" style={{marginLeft:"260px"}}>Small Ruminant Deworming and Mineral Bricks</h2>
                </div>
                <div style={{display:"flex", marginTop:"20px"}} class="kop" id="fgh">
                    <img class="pitr" style={{marginLeft:"25px"}} src="srdmb2324-1.jpeg"/>
                    <img class="pitr" style={{marginLeft:"20px"}}src="srdmb2324-2.jpeg"/>
                    <img class="pitr" style={{marginLeft:"20px"}}src="srdmb2324-3.jpeg"/>
                </div>
                <div style={{display:"flex", marginTop:"20px"}} class="kop">
                     <img class="pitr"  style={{marginLeft:"245px",width:"45%"}}src="srdmb2324-4.jpeg"/>
                </div>  
  
            <div class="hip" style={{marginTop:"20px"}}>
                <h2 class="aim">Water Can Distruibution</h2>
                </div>
                <div style={{display:"flex", marginTop:"20px"}} class="kop" id="fgh">
                    <img class="pitr" style={{marginLeft:"20px"}}src="wcd2324-1.jpg"/>
                    <img class="pitr" style={{marginLeft:"25px"}}src="wcd2324-2.jpg"/>
                    <img class="pitr" style={{marginLeft:"25px"}}src="wcd2324-3.jpg"/>
                </div>
     
            <div class="hip" style={{marginTop:"20px"}}>
                <h2 class="aim">Output Marketing</h2>
                </div>
                <div style={{display:"flex", marginTop:"10px"}} class="kop">
                    <img class="pitr" style={{marginLeft:"245px",width:"45%"}} src="opm2324-1.jpg"/>
                </div>
            
                <div class="hip" style={{marginTop:"20px"}}>
                    <h2 class="aim">Others</h2>
                    <h3>Meeting with School Staff</h3>
                    </div>
                    <div style={{display:"flex", marginTop:"20px"}} class="kop">
                        <img class="pitr" style={{marginLeft:"245px",width:"45%"}}src="mss2324-1.jpeg"/>
                    </div>
                    <h3>Other</h3>
                    <div style={{display:"flex", marginTop:"20px"}} class="kop">
                        <img class="pitr" style={{width:"45%",marginLeft:"20px"}}src="oth2324-1.jpg"/>
                        <img class="pitr" style={{marginLeft:"50px",width:"45%"}}src="oth2324-2.jpeg"/>
                    </div>  
               
            

            
                <h1 class="aih">FY 2022-2023 Activities Photos</h1>
               
                    <div style={{display:"flex",marginTop:"20px"}} class="kop" id="fgh">
                        <img class="pitr" style={{marginLeft:"25px"}} src="fy2223-1.jpg"/>
                        <img class="pitr" style={{marginLeft:"20px"}} src="fy2223-2.jpg"/>
                        <img class="pitr"  style={{marginLeft:"20px"}} src="fy2223-3.jpg"/>
                    </div>
                    <div style={{display:"flex",marginTop:"20px"}} class="kop" id="fgh">
                        <img class="pitr" style={{marginLeft:"25px"}}  src="fy2223-4.jpg"/>
                        <img class="pitr" style={{marginLeft:"20px"}} src="fy2223-5.jpg"/>
                        <img class="pitr"  style={{marginLeft:"20px"}} src="fy2223-6.jpg"/>
                    </div>
                    <div style={{display:"flex",marginTop:"20px"}} class="kop" id="fgh">
                        <img class="pitr" style={{marginLeft:"25px"}} src="fy2223-7.jpg"/>
                        <img class="pitr" style={{marginLeft:"20px"}} src="fy2223-8.jpg"/>
                        <img class="pitr"  style={{marginLeft:"20px"}} src="fy2223-9.jpg"/>
                    </div>
                    <div style={{display:"flex" ,marginTop:"20px"}} class="kop" id="fgh">
                        <img class="pitr"  style={{marginLeft:"25px"}} src="fy2223-10.jpg"/>
                        <img class="pitr" style={{marginLeft:"20px"}} src="fy2223-11.jpg"/>
                        <img class="pitr"  style={{marginLeft:"20px"}} src="fy2223-12.jpg"/>
                    </div>
                    <div style={{display:"flex",marginTop:"20px"}} class="kop" id="fgh">
                        <img class="pitr" style={{marginLeft:"25px"}}  src="fy2223-13.jpg"/>
                        <img class="pitr" style={{marginLeft:"20px"}}src="fy2223-14.jpg"/>
                        <img class="pitr"  style={{marginLeft:"20px"}}src="fy2223-15.jpg"/>
                    </div>
                    <div style={{display:"flex" ,marginTop:"20px"}} class="kop" id="fgh">
                        <img class="pitr"style={{marginLeft:"25px"}} src="fy2223-16.jpg"/>
                        <img class="pitr" style={{marginLeft:"20px"}} src="fy2223-17.jpg"/>
                        <img class="pitr"  style={{marginLeft:"20px"}} src="fy2223-18.jpg"/>
                    </div>
                    <div style={{display:"flex",marginTop:"20px"}} class="kop" id="fgh">
                        <img class="pitr"style={{marginLeft:"25px"}} src="fy2223-19.jpg"/>
                        <img class="pitr" style={{marginLeft:"20px"}} src="fy2223-20.jpg"/>
                        <img class="pitr"  style={{marginLeft:"20px"}} src="fy2223-21.jpg"/>
                    </div>
                    <div style={{display:"flex",marginTop:"20px"}} class="kop" id="fgh">
                        <img class="pitr" style={{marginLeft:"25px"}} src="fy2223-22.jpg"/>
                        <img class="pitr" style={{marginLeft:"20px"}} src="fy2223-23.jpg"/>
                        <img class="pitr"  style={{marginLeft:"20px"}} src="fy2223-24.jpg"/>
                    </div>
                    <div style={{display:"flex",marginTop:"20px"}} class="kop" id="fgh">
                        <img class="pitr" style={{marginLeft:"25px"}} src="fy2223-25.jpg"/>
                        <img class="pitr" style={{marginLeft:"20px"}} src="fy2223-26.jpg"/>
                        <img class="pitr"  style={{marginLeft:"20px"}} src="fy2223-27.jpg"/>
                    </div>
                    <div style={{display:"flex",marginTop:"20px"}} class="kop" id="fgh">
                        <img class="pitr" style={{marginLeft:"25px"}} src="fy2223-28.jpg"/>
                        <img class="pitr" style={{marginLeft:"20px"}} src="fy2223-29.jpg"/>
                        <img class="pitr"  style={{marginLeft:"20px"}} src="fy2223-30.jpg"/>
                    </div>
                    <div style={{display:"flex",marginTop:"20px"}} class="kop" id="fgh">
                        <img class="pitr" style={{marginLeft:"25px"}} src="fy2223-31.jpg"/>
                        <img class="pitr" style={{marginLeft:"20px"}} src="fy2223-32.jpg"/>
                        <img class="pitr"  style={{marginLeft:"20px"}} src="fy2223-33.jpg"/>
                    </div>
                    <div style={{display:"flex",marginTop:'20px'}} class="kop" id="fgh">
                        <img class="pitr" style={{marginLeft:"25px"}}src="fy2223-34.jpg"/>
                        <img class="pitr" style={{marginLeft:"20px"}} src="fy2223-35.jpg"/>
                        <img class="pitr"  style={{marginLeft:"20px"}} src="fy2223-36.jpg"/>
                    </div>
                    <div style={{display:"flex",marginTop:"20px"}} class="kop">
                        <img class="pitr" style={{width:"45%",marginLeft:"20px"}} src="fy2223-37.jpg"/>
                        <img class="pitr" style={{marginLeft:"50px",width:"45%"}} src="fy2223-38.jpg"/>
                    </div>
               
            

           
                <h1 class="aih">FY 2021-2022 Activities Photos</h1>
               
                
                    <div style={{display:"flex",marginTop:"20px"}} class="kop" id="fgh">
                        <img class="pitr" style={{marginLeft:"25px"}}src="fy2122-1.jpg"/>
                        <img class="pitr" style={{marginLeft:"20px"}} src="fy2122-2.jpg"/>
                        <img class="pitr"  style={{marginLeft:"20px"}} src="fy2122-3.jpg"/>
                    </div>
                    <div style={{display:"flex",marginTop:"20px"}} class="kop" id="fgh">
                        <img class="pitr" style={{marginLeft:"25px"}} src="fy2122-4.jpg"/>
                        <img class="pitr" style={{marginLeft:"20px"}} src="fy2122-5.jpg"/>
                        <img class="pitr"  style={{marginLeft:"20px"}} src="fy2223-6.jpg"/>
                    </div>
                    <div style={{display:"flex",marginTop:"20px"}} class="kop" id="fgh">
                        <img class="pitr" style={{marginLeft:"25px"}} src="fy2122-7.jpg"/>
                        <img class="pitr" style={{marginLeft:"20px"}} src="fy2122-8.jpg"/>
                        <img class="pitr"  style={{marginLeft:"20px"}} src="fy2122-9.jpg"/>
                    </div>
                    <div style={{display:"flex",marginTop:"20px"}} class="kop" id="fgh">
                        <img class="pitr"style={{marginLeft:"25px"}} src="fy2122-10.jpg"/>
                        <img class="pitr" style={{marginLeft:"20px"}} src="fy2122-11.jpg"/>
                        <img class="pitr"  style={{marginLeft:"20px"}} src="fy2122-12.jpg"/>
                    </div>
                    <div style={{display:"flex",marginTop:'20px'}} class="kop" id="fgh">
                        <img class="pitr" style={{marginLeft:"25px"}} src="fy2122-13.jpg"/>
                        <img class="pitr" style={{marginLeft:"20px"}}src="fy2122-14.jpg"/>
                        <img class="pitr"  style={{marginLeft:"20px"}}src="fy2122-15.jpg"/>
                    </div>
                    <div style={{display:"flex",marginTop:"20px"}} class="kop" id="fgh">
                        <img class="pitr" style={{marginLeft:"25px"}}src="fy2122-16.jpg"/>
                        <img class="pitr" style={{marginLeft:"20px"}} src="fy2122-17.jpg"/>
                        <img class="pitr"  style={{marginLeft:"20px"}} src="fy2122-18.jpg"/>
                    </div>
                    <div style={{display:"flex",marginTop:"20px"}} class="kop" id="fgh">
                        <img class="pitr" style={{marginLeft:"25px"}} src="fy2122-19.jpg"/>
                        <img class="pitr" style={{marginLeft:"20px"}} src="fy2122-20.jpg"/>
                        <img class="pitr"  style={{marginLeft:"20px"}} src="fy2122-21.jpg"/>
                    </div>
                    <div style={{display:"flex",marginTop:'20px'}} class="kop" id="fgh">
                        <img class="pitr" style={{marginLeft:"25px"}} src="fy2122-22.jpg"/>
                        <img class="pitr" style={{marginLeft:"20px"}} src="fy2122-23.jpg"/>
                        <img class="pitr"  style={{marginLeft:"20px"}} src="fy2122-24.jpg"/>
                    </div>
                    <div style={{display:"flex",marginTop:"20px"}} class="kop" id="fgh">
                        <img class="pitr" style={{marginLeft:"25px"}} src="fy2122-25.jpg"/>
                        <img class="pitr" style={{marginLeft:"20px"}} src="fy2122-26.jpg"/>
                        <img class="pitr"  style={{marginLeft:"20px"}} src="fy2122-27.jpg"/>
                    </div>
                    <div style={{display:"flex",marginTop:'20px'}} class="kop" id="fgh">
                        <img class="pitr" style={{marginLeft:"25px"}}src="fy2122-28.jpg"/>
                        <img class="pitr" style={{marginLeft:"20px"}} src="fy2122-29.jpg"/>
                        <img class="pitr"  style={{marginLeft:"20px"}} src="fy2122-30.jpg"/>
                    </div>
              
            
      
        </>
    )
}