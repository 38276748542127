import React from "react";
// import './videos.css';
import './photo.css';
export default function Videos(){
    return(
        <>
        <div class="main">
        <div class="hip">
            <h1 class="aih">Videos</h1>
            </div>
                <div class="gallery-gri" style={{marginLeft:"170px"}}>
                    <figure class="gallery-ite" >
                        <iframe width="660" height="315"  src="https://www.youtube.com/embed/RyMW9WOFtPI?si=BdXC-6QKiNuBV6he" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                        <figcaption class="cap">BRS opening by Sudheer Reddy</figcaption>
                    </figure>
                    <figure class="gallery-ite">
                        <iframe width="660" height="315" src="https://www.youtube.com/embed/fWAPpR_Zjy8?si=4fDNwJ797dyulw0g" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                        <figcaption class="cap"></figcaption>
                    </figure>
                    <figure class="gallery-ite">
                    <iframe width="660" height="315" src="https://www.youtube.com/embed/mKBaftqlHe8?si=EhJqhMghTLg2HUD4" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                        <figcaption class="cap">Bio-Resource Center Piler</figcaption>
                    </figure>
                </div>
       </div>
        </>
    )
}