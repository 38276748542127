import React from "react";
export default function Gallery(){
    return(
        <div class="main">
              <div class="hip">
            <h1 class="aih">Gallery</h1>
          </div>
                <a style={{textDecoration:"none"}} href="/photos"><h2 class="aim" style={{marginLeft:"60px"}}>Photos</h2></a>
              
                    <div style={{display:"flex",marginTop:"20px"}} class="kop" id="fgh">
                        <img class="pitr" style={{marginLeft:"25px"}}  src="fy2122-1.jpg"/>
                        <img class="pitr" style={{marginLeft:"20px"}} src="fy2122-2.jpg"/>
                        <img class="pitr"  style={{marginLeft:"20px"}} src="fy2122-3.jpg"/>
                    </div>
                    <div style={{display:"flex",marginTop:"20px"}} class="kop" id="fgh">
                        <img class="pitr" style={{marginLeft:"25px"}} src="fy2122-4.jpg"/>
                        <img class="pitr" style={{marginLeft:"20px"}} src="fy2122-5.jpg"/>
                        <img class="pitr"  style={{marginLeft:"20px"}} src="fy2223-6.jpg"/>
                    </div>
                    <div style={{display:"flex",marginTop:"20px"}} class="kop" id="fgh">
                        <img class="pitr" style={{marginLeft:"25px"}}src="fy2122-7.jpg"/>
                        <img class="pitr" style={{marginLeft:"20px"}} src="fy2122-8.jpg"/>
                        <img class="pitr"  style={{marginLeft:"20px"}} src="fy2122-9.jpg"/>
                    </div>
            
        
            <div class="hip" style={{marginTop:"20px"}}>
                <a style={{textDecoration:"none"}} href="/videos"><h2 class="aim">Videos</h2></a>
                </div>
                <div class="gallery-gri">
                    <figure class="gallery-ite" style={{marginLeft:"280px"}}>
                        <iframe width="560" height="315" src="https://www.youtube.com/embed/RyMW9WOFtPI?si=BdXC-6QKiNuBV6he" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                        <figcaption>BRS opening by Sudheer Reddy</figcaption>
                    </figure>
                    <figure class="gallery-ite" style={{marginLeft:"280px"}}>
                        <iframe width="560" height="315" src="https://www.youtube.com/embed/fWAPpR_Zjy8?si=4fDNwJ797dyulw0g" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                        <figcaption></figcaption>
                    </figure>
                    <figure class="gallery-ite" style={{marginLeft:"280px"}}>
                    <iframe width="560" height="315" src="https://www.youtube.com/embed/mKBaftqlHe8?si=EhJqhMghTLg2HUD4" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                        <figcaption>Bio-Resource Center Piler</figcaption>
                    </figure>
                    </div>
                </div>
        
    )   
}