import React from "react";
import './pressclips.css';
export default function Pressclips19(){
   
    
    
    return(
       
        <div>
        <div class="hiplk">
            <div class="hip">
        <h2 class="aim">2019</h2>
        </div>
        <div style={{display:"flex", marginTop:"60px"}} class="kop" id="fgh">
            <img class="pitr" src="NPC-2019-1.jpg"/>
            <img class="pitr" style={{marginLeft:"30px"}}src="NPC-2019-2.jpg"/>
            <img class="pitr"  style={{marginLeft:"30px"}}src="NPC-2019-3.jpg"/>
        </div>
        <div style={{display:"flex"}} class="kop" id="fgh">
            <img class="pitr" src="NPC-2019-4.jpg"/>
            <img class="pitr" style={{marginLeft:"30px"}}src="NPC-2019-5.jpg"/>
            <img class="pitr"  style={{marginLeft:"30px"}}src="NPC-2019-6.jpg"/> 
        </div>
        <div style={{display:"flex"}} class="kop">
            <img class="pitr" style={{width:"49%"}} src="NPC-2019-7.jpg"/>
            <img class="pitr" style={{width:"49%",marginLeft:"30px"}}src="NPC-2019-8.jpg"/>
        </div>
        </div>
        </div>
       
        
    )
} <div>
<div class="mainkl">
    <div class="hip">
        <h2 class="aim">2019</h2>
        <div style={{display:"flex"}} class="kopmnb" id="fgh">
            <img class="pitrm" src="NPC-2019-1.jpg"/>
            <img class="pitrm" style={{marginLeft:"30px"}}src="NPC-2019-2.jpg"/>
            <img class="pitrm"  style={{marginLeft:"30px"}}src="NPC-2019-3.jpg"/>
        </div>
        <div style={{display:"flex"}} class="kop" id="fgh">
            <img class="pitr" src="NPC-2019-4.jpg"/>
            <img class="pitrm" style={{marginLeft:"30px"}}src="NPC-2019-5.jpg"/>
            <img class="pitrm"  style={{marginLeft:"30px"}}src="NPC-2019-6.jpg"/> 
        </div>
        <div style={{display:"flex"}} class="kop">
            <img class="pitrm" style={{width:"49%"}} src="NPC-2019-7.jpg"/>
            <img class="pitrm" style={{width:"49%",marginLeft:"30px"}}src="NPC-2019-8.jpg"/>
        </div>
    </div>
</div>

</div>
