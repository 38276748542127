import React from "react";
import './brs.css';

export default function Bioresourcecenters(){


    return(

        <div>
            <h2 style={{marginTop:"30px",}} id="pmn">BIO RESOURCE CENTRE - PILER </h2>
            <div  id="sha">
            <p  id="cli">Climate Smart Village Bio Resource Centre Encouraging climatesmart agriculture Rising temperatures and decreasing water availability are reducing yields particularly in Annamayya district a whereagriculture  vital for the food security a the population Extreme weather event such as drought and floods are making cropping and animal production even more prone  crop failure. Agriculture must also adaptas to changes in climate in order to provide food security. Ecological farming method using naturally prepared dung-based inoculants to are the best way of practicing agriculture in severe drought conditions and traditional non hybrid seed are quite resilient against to the vagaries of the climate. The inoculants can be prepared ats a single place and one such unit is Bio Resource Centre (BRC) a to benefit more farmer. In order to cope with the change in climate in the drought prone regions of Annamayya district in the statesa of Andhra Pradesh, PILER FPC is implementing a project, ‘Climates Smart Village’ (CSV) at piler, The project is being funded by thea Aurabindo Pharma Foundation and aims to promote affordabl and replicable adaptationand mitigation practices to enhance livelihoods of vulnerable communities in the district of Annamayya. One a of the major interventions in the project is to reduce the usages of chemical fertilizers and replace with dung-based inoculants which will improve the health of soil and the quality of the crop the a produce.  </p>
            <img id="ose"src="biosearch.jpeg"/>
            </div>
           <p style={{marginLeft:"50px", color:"rgb(66, 65, 65)",marginTop:"40px",marginRight:"20px",lineHeight:"30px"}} id="sou">Bio Resource Centre (BRC) Bio Resource Centre was established under the component ‘SMART Agriculture. BRC is an enterprise, where time tested, locally prepared products/formulations utilizing biological entities or biologically derived inputs useful for improving soil health, crop growth, pest or disease management are made available for purchase by farmers. BRC serves as single-stop shop for all bio input needs of farmers in the area. It can also be an effective service delivery mechanism for climate resilient farming practices. The potential items prepared at BRC could be: Improving soil fertility and soil health </p>
           
           <img style={{height:"20px" ,marginLeft:"50px",marginTop:"10px"}} src="next.png"/><p class="act" style={{marginLeft:"80px",marginTop:"-27px"}}>Seeds of green manuring crops </p>
  <img style={{height:"20px" ,marginLeft:"50px",marginTop:"-15px"}} src="next.png"/><p class="act"  style={{marginLeft:"80px",marginTop:"-30px"}}>Vermiculture / compost
</p>
<img style={{height:"20px" ,marginLeft:"50px",marginTop:"-15px"}} src="next.png"/><p class="act"style={{marginLeft:"80px",marginTop:"-30px"}}> Neem / karanj cake 
</p>
<img style={{height:"20px" ,marginLeft:"50px",marginTop:"-15px"}} src="next.png"/><p class="act"style={{marginLeft:"80px",marginTop:"-30px"}}>Cow dung/cow urine

</p>
<img style={{height:"20px" ,marginLeft:"50px",marginTop:"-15px"}} src="next.png"/><p class="act"style={{marginLeft:"80px",marginTop:"-30px"}}>Microbial preparations like Jeevamrith, Ghana Jeevamrith, Waste Decomposer  

</p>

<img style={{height:"20px" ,marginLeft:"50px",marginTop:"0px"}} src="next.png"/><p class="act" style={{marginLeft:"80px",marginTop:"-27px"}}>Bio fertilizer cultures: VAM (Vesicular Arbuscular Mycorrhizae), Azolla, Rhizobium, PSB (Phosphate Solubilizing Bacteria), Azospirillum, Azatobacter, Potash / Zinc Mobilizers etc Pest or disease management      </p>
  <img style={{height:"20px" ,marginLeft:"50px",marginTop:"-15px"}} src="next.png"/><p class="act"  style={{marginLeft:"80px",marginTop:"-30px"}}>Seeds / seedlings of trap crops
</p>
<img style={{height:"20px" ,marginLeft:"50px",marginTop:"-15px"}} src="next.png"/><p class="act"style={{marginLeft:"80px",marginTop:"-30px"}}>	 Botanical decoctions 

</p>
<img style={{height:"20px" ,marginLeft:"50px",marginTop:"-15px"}} src="next.png"/><p class="act"style={{marginLeft:"80px",marginTop:"-30px"}}>	 Panchagavya / Dasaparni  


</p>
<img style={{height:"20px" ,marginLeft:"50px",marginTop:"-15px"}} src="next.png"/><p class="act"style={{marginLeft:"80px",marginTop:"-30px"}}>	 Neemastra / bramhastra / agniastra 
</p>
<img style={{height:"20px" ,marginLeft:"50px",marginTop:"-15px"}} src="next.png"/><p class="act"style={{marginLeft:"80px",marginTop:"-30px"}}>	 NSKE (Neem Seed Kernal Extract)  
 
</p>
<img style={{height:"20px" ,marginLeft:"50px",marginTop:"-15px"}} src="next.png"/><p class="act"style={{marginLeft:"80px",marginTop:"-30px"}}>	Bio pesticides – Beaveria, Verticillium, Trichoderma, Pseudomonas, NPV (Nuclear Polyhydrosis Virus) formulations / cultures. Habitat management 

</p>
<img style={{height:"20px" ,marginLeft:"50px",marginTop:"-15px"}} src="next.png"/><p class="act"style={{marginLeft:"80px",marginTop:"-30px"}}>	 Seeds / Seedlings of Glyricidia, Drumstick, Pongam, Neem  
 
</p>
<img style={{height:"20px" ,marginLeft:"50px",marginTop:"-15px"}} src="next.png"/><p class="act"style={{marginLeft:"80px",marginTop:"-30px"}}>	Seeds for Border crop/Inter crop/Cover crop/ Bio-fencing/ Fodder  
 
</p>
<img style={{height:"20px" ,marginLeft:"50px",marginTop:"-15px"}} src="next.png"/><p class="act"style={{marginLeft:"80px",marginTop:"-30px"}}> Yellow/White Sticky Traps, Pheromone Traps, Fly traps, Light traps etc. One entrepreneurs Bodeesha vali from Piler village, Piler Mandal mandal identified and taken for exposure.  
 
</p>
<div id="ght">
<p id="el">The BRCs established are preparing the following products: The main product of the piler Bio resource Centre is ANNEMEAL, Bio Pesticide and Bio Fertilizer. Application of ANNEMEAL,bio pesticides and fertilizers is an important intervention in the Climate Smart Village Project and expected to result in the following outcomes:  </p>
<img src="bottle-remove.png" id="iw"/>
</div>
<div class="mom" id="in" style={{width:"100%",marginTop:"450px"}}>
<p style={{marginLeft:"50px",color:"rgb(66, 65, 65)",marginTop:"-380px",paddingTop:"30px",fontSize:"17px",lineHeight:"30px"}}>Avoids usage of synthetic fertilizers and pesticides and hence contributes to tackle climate change by reducing greenhouse gas emissions  

</p>
<p style={{marginLeft:"50px", color:"rgb(66, 65, 65)",marginTop:"10px",fontSize:"17px",lineHeight:"30px"}}>Minimizes energy consumption by eliminating the energy required to manufacture synthetic fertilizers, and by using internal farm inputs, thus reducing fuel used for transportation   

</p>
<p style={{marginLeft:"50px", color:"rgb(66, 65, 65)",marginTop:"10px",fontSize:"17px",lineHeight:"30px"}}>Establishes closed nutrient cycles and minimizes losses via runoff    

</p>
<p style={{marginLeft:"50px", color:"rgb(66, 65, 65)",marginTop:"10px",fontSize:"17px",lineHeight:"30px"}}>Contributes to sustainable climate-friendly production system that delivers enough food  

</p>
<p style={{marginLeft:"50px", color:"rgb(66, 65, 65)",marginTop:"10px",fontSize:"17px",lineHeight:"30px"}}>Helps farmers adapt to climate change because high soil organic matter content and makes soils more resilient to floods, droughts, and land degradation processes  
</p>
<p style={{marginLeft:"50px", color:"rgb(66, 65, 65)",marginTop:"10px",fontSize:"17px",lineHeight:"30px"}}> Minimizes risk as a result of stable agro-ecosystems and yields, and lower production costs</p>
<p style={{marginLeft:"50px", color:"rgb(66, 65, 65)",marginTop:"10px",fontSize:"17px",lineHeight:"30px"}}> Requires locally available materials for preparation of bio pesticides and bio fertilizers and thus reduction in cost and saving of time and energy. </p>
<p style={{marginLeft:"50px", color:"rgb(66, 65, 65)",marginTop:"10px",fontSize:"17px",lineHeight:"30px"}}> Avoids use of synthetic fertilizers, pesticides and genetically modified organisms results in minimizing pollution of air, soil and water, and optimizes the health and productivity of interdependent communities of plants, animals and people   </p>
<p style={{marginLeft:"50px", color:"rgb(66, 65, 65)",marginTop:"10px",fontSize:"17px",lineHeight:"30px"}}>Provides nutritious food which adds to healthy diet and enhanced immunity </p>
           </div>
            </div>
    )
}