import React  from "react";
import './about.css';


export default function About(){

    return(
        <div>
            <p class="pil">PILER FARMERS PRODUCER COMPANY LIMITED registered in the year 2018 under </p>
            <p class="act">Company Act with Reg.No.U01110AP2018PTC109909 is currently having 780 farmer members in this mandal level Farmer Producer Group (FPO). PILER FARMERS PRODUCER COMPANY LIMITED is formed to benefit its member farmers and to become a leading input provider in Pileru mandal. APMAS (Mahila Abhivruddhi Society, Andhra Pradesh), using the financial assistance from Department of Horticulture, Govt. of Andhra Pradesh, is the promoter of this FPO under the project, “Promotion and Strengthing of FPOs as Business start-Ups” in Anantapur, Chittoor and Kurnool districts of Andhra Pradesh. Currently, the FPO is involved in activities of demand based input supply like tarpaulins, sprayers, fodder, fertilizers and Mobile Veterinary services etc. To move a step ahead in providing various services to its member farmers, the FPO has come up with a Business Proposal. The proposed business is to provide farm equipments, micro nutrients, bio- fertilizers and bio-pesticides through Common Facility Centre. In this business model, the Common Facility Centre provides essential services like land preparation, seeding, weeding and others by renting out its machinery. Also, the service centre provides micro nutrients, bio-fertilizers and bio-pesticides required for the major crops growing in the mandal. Common Facility Centre provides these quality inputs at a price lower than in the market. The farmer producers group mobilized equity amount of Rs. 10, 00,000 from its shareholders. For the purpose of establishing this business, the group seeks the remaining amount as grant support. 

 

</p>
        </div>
    )
}