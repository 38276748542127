import './App.css';
import ImageSlider from './ImageSlider';
import { SliderData } from './SliderData';
import { BsAsterisk } from "react-icons/bs";

function App() {
  
  return (
 <>
<ImageSlider slides={SliderData}/>
<div style={{marginTop:"-150px"}}>
          <marquee behavior="alternate"><h5 id="az" ><img src="tenor.gif" alt="" height={"50px"} width={"70px"}style={{marginTop:"10px"}}/><span style={{marginTop:"-40px"}}>Wellcome to APMAS PELIRU</span><img src="tenor.gif" alt="" height={"50px"} width={"70px"}/></h5></marquee>  
           
      </div> 
      <div class="hip">
<h2 class="aim">PILER FPC</h2>
</div>
<p class="ince">     <span style={{marginLeft:"120px"}}></span><b>PILER FARMERS PRODUCER COMPANY LIMITED</b> registered in the year 2018 under Company Act with<b> Reg.No.U01110AP2018PTC109909</b> is currently having 780 farmer members in this mandal level<b> FARMERS PRODUCER ORGANISATION GROUP (FPO)</b>. PILER FARMERS PRODUCER COMPANY LIMITED is formed to benefit its member is a <br/>farmers and to become a leading input provider in<b>PILERU mandal.</b> </p>
<div class="hip">
<h2 class="aim">AIM</h2>
</div>
<li class="i">  Increase our community.</li>
<li class="i">Development  of farming techniques.</li>
<li class="i">Making of young entreprenures.</li>
<li class="i">Awareness in land soil fertility.</li>
<li class="i">Providing best quality of seeds and fertilizers.</li>
<li class="i">Viewing domestic animal along with farming(best care and health monitoring via AUROBINDO).</li>


<div class="fpo"><h3 class="aims">FPOs help farmers access resources such as credit, inputs, technology, and market information </h3></div>

<p class="act" style={{marginTop:"30px"}}>FPO centers often provide training and capacity-building programs to enhance the skills and knowledge of farmers. </p>
<p class="act" style={{marginTop:"-5px"}}>Investing in research and development initiatives to improve processing technologies, develop new products, and address challenges facing the food processing industry. </p>
<p class="act" style={{marginTop:"-5px"}}>Offering training programs, workshops, and technical assistance to enhance the skills and capabilities of food processors, entrepreneurs, and workers in the industry. </p>

<p class="act" style={{marginTop:"-5px"}}>They may also undergo specific training provided by their government's foreign service or diplomatic academy. </p>
<p class="act" style={{marginTop:"-5px"}}>: FPOs may work in government agencies responsible for formulating foreign policy </p>
<p class="act" style={{marginTop:"-5px"}}>This is perhaps the most traditional career path for FPOs. Diplomats represent their country's interests abroad </p>
<p class="act" style={{marginTop:"-5px"}}>They work in embassies, consulates, and diplomatic missions around the world. </p>
<p class="act" style={{marginTop:"-5px"}}>FPOs may work in government agencies, think tanks, or research institutions. </p>
<p class="act" style={{marginTop:"-5px"}}> Aalyzing international issues, conducting policy research. </p>
<p class="act" style={{marginLeft:"35px"}}> Providing recommendations to policymakers. </p>
<p class="act"style={{marginTop:"-5px"}}>They play a crucial role in shaping foreign policy decisions and strategies. </p>
<p class="act" style={{marginTop:"-5px"}}>FPOs may work for multinational corporations, using their diplomatic skills to navigate complex international environments, </p>
<div class="hip" style={{marginTop:"40px",height:"60px",borderRadius:"5PX"}}>
  <h1 class="aim">Our Vision </h1>
 </div>
 <p class="act">Farmers as producers are unable to realize the right value of their produce. The defragmentation of land, lack of awareness, distrust in scientific practices, less inclination towards technology adoption leads to under production against the optimal potential. The absence of adequate marketing infrastructure, presence of middle man, lack of collectivization effort leads to grabbing less marketing opportunities and earnings. </p>
 <p class="act">Collectivization of producers, especially small and marginal farmers, into producer organizations is emerging as one of the most effective pathways to address the many challenges of agriculture and most importantly, improved access to investments, technology and inputs and markets. Department of Agriculture and Cooperation, Ministry of Agriculture, Govt. of India has also identified farmer producer organization (FPO) registered under the special provisions of the Companies Act, 1956 as the most appropriate institutional form around which to mobilize farmers and build their capacity to collectively leverage their production and marketing strength. Vision of the FPO is to build prosperous and sustainable member-owned producer organizations that enable farmers to enhance productivity through efficient, cost-effective and sustainable resource use and realize higher returns for their produce, through collective action. </p>
 <div class="hip" style={{marginTop:"40px",height:"60px",borderRadius:"5PX"}}>
  <h1 class="aim">Our Mission  </h1>
 </div>
 <p class="act">Piler Farmers Producers Company limited endeavours to develop and expand environmentally, technically, socially sound natural resource interventions leading to empowerment of rural community including women to ensure equitable and sustainable development and poverty reduction. 

</p>
<div class="hip" style={{marginTop:"30px",height:"60px",borderRadius:"5PX"}}>
  <h1 class="aim">Our Team </h1>
 </div>
 {/* <div >
 <div class='actorW'>
             <img class="fekkls" src="bharghavi-remove.png"/><br/>
             <div class="dow" style={{width:"100%",boxShadow:"0px 0px 1px #000000",marginTop:"50px",height:"50px"}}>
             <h5 style={{marginTop:"-15px",marginLeft:"-6px"}}class="saha">Bharghavi-B.Sc.Veterniary  </h5>
             <p class="dent" style={{marginLeft:"50px",marginTop:"-35px",fontSize:"17px"}}>Project Manager-Piler  </p>
             <p class="dent" style={{marginLeft:"5px",marginTop:"-16px" ,fontSize:"17px"}}>Farmers Producers Company, Piler  </p>
             </div>
             </div>
             <div class='actoW'>
                         <img class="feks" src="prathy-remove.png"/><br/>
                         <div class="dow" style={{width:"100%",boxShadow:"0px 0px 1px #000000",marginTop:"50px",height:"50px"}}>
                         <h5  style={{marginLeft:"35px",marginTop:"-15px"}}class="saha">Muliki.Prathyusha   </h5>
                         <p class="dent" style={{marginLeft:"70px",marginTop:"-35px",fontSize:"17px"}}>B.Sc. Horticulture   </p>
             <p class="dent" style={{marginLeft:"1px",marginTop:"-16px" ,fontSize:"17px"}}>Farmers Producers Company, Piler  </p>
             </div>
                         </div>
                         <div class='actW'>
            <img class="fekks" src="pooji-remove.png"/>
            <div class="dow" style={{width:"100%",boxShadow:"0px 0px 1px #000000",marginTop:"50px",height:"50px"}}>
            <h5 class="saha" style={{marginTop:"-15px",marginLeft:"-4px"}}> Poojitha-B.Sc.Life Sciences  </h5>
            <p class="dent" style={{marginLeft:"30px",marginTop:"-35px",fontSize:"17px"}}>Accountants & Marketing    </p>
             <p class="dent" style={{marginLeft:"5px",marginTop:"-16px" ,fontSize:"17px"}}>Farmers Producers Company, Piler  </p>
 </div>
            </div>
            </div> */}
            <div class="container">
  <div class='actorWk'>
    <img class="fekkls" src="bharghavi-remove.png"/><br/>
    <div class="dow">
      <h5 class="saha">Bharghavi-B.Sc.Veterniary</h5>
      <p class="dent">Project Manager-Piler</p>
      <p class="dent">Farmers Producers Company, Piler</p>
    </div>
  </div>

  <div class='actoWl'>
    <img class="feks" src="prathy-remove.png"/><br/>
    <div class="dow">
      <h5 class="saha">Muliki.Prathyusha</h5>
      <p class="dent">B.Sc. Horticulture</p>
      <p class="dent">Farmers Producers Company, Piler</p>
    </div>
  </div>

  <div class='actWn' >
    <img class="fekks" src="pooji-remove.png"/>
    <div class="dow">
      <h5 class="saha">Poojitha-B.Sc.Life Sciences</h5>
      <p class="dent">Accountants & Marketing</p>
      <p class="dent">Farmers Producers Company, Piler</p>
    </div>
  </div>
</div>
 <div class="hip" style={{marginTop:"40px",height:"60px",borderRadius:"5PX"}}>
  <h1 class="aim">Our Partners</h1>
 </div>
 <div class="pnb">
    <img class="ssx" id="ti" src="Aurobindo.jpg"/>
    <img class="ssx" id="tq" src="images.png"/>
    <img class="ssx" id="tz" src="apmas-logo-01.png"/>
</div>
 
</>
  )

}

export default App;
