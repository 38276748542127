import React from "react";

import './nav.css';
import { Link } from "react-router-dom";

export default function Navbar(){

return(
    <div style={{marginTop:"-130px"}}>  <header>
    <div class="logo1"><img class="log" src="apmas log.jpg"/></div>
    <div class="name1">
        <h1 >PILER FARMERS PRODUCERS COMPANY LTD.</h1>
        <h4 style={{marginTop:"-25px",marginLeft:"20PX",fontSize:"19PX"}}>Reg. no: U01110AP2018PTC109909 </h4>

        <h2 style={{marginTop:"-5px"}}>PILER</h2>
    </div>
    <div class="logo2"><img class="logs" src="peliru.png"/></div>
</header>
         
         <nav class="bvc" >
            <ul>
                <li><Link to="/">Home</Link></li>
                <li class="submenu">
                    <Link to="/about">About Us</Link>
                        <ul class="dropdown">
                            <li><Link to="/apmasabout">APMAS</Link></li><li><Link to="/staff">Staff</Link></li>
                            <li><Link to="/boardofdirector">BOD</Link></li>
                           
                        </ul>
                </li>
                <li class="submenu">
                    <Link to="/service">Services</Link>
                    <ul class="dropdown">
                        <li><Link to="/agiriculture">Agriculture</Link></li><li><Link to="/horticulture">Horticulture</Link></li>
                        <li><Link to="#">Animal Husbandary</Link></li><li><Link to="/womenempowerment">Women Empowerment</Link></li>
                        <li><Link to="/enterprenuredevelopment">Enterprenure Development</Link></li><li><Link to="/bioresourcecenters">Bio-Resource Center</Link></li>
                    </ul>
                </li>
                <li class="submenu">
                    <Link to="/gallery">Gallery</Link>
                    <ul class="dropdown">
                        <li><Link to="/photos">Photos</Link></li><li><Link to="/videos">Vides</Link></li>
                    </ul>
                </li>
                <li class="submenu">
                    <Link to="/pressclipping">Newspaper Clippings</Link>
                    <ul class="dropdown">
                        <li><Link to="/pressclips19">2019</Link></li><li><Link to="/pressclips20">2020</Link></li>
                        <li><Link to="/pressclips21">2021</Link></li><li><Link to="/pressclips22">2022</Link></li>
                        <li><Link to="/pressclips23">2023</Link></li><li><Link to="/pressclips24">2024</Link></li>
                    </ul>
                </li>
                <li class="submenu"><Link to="#">Testimonals</Link></li>
                <li class="submenu">
                    <Link to="/marketing"> Marketing</Link>
                    <ul class="dropdown">
                        <li><Link to="/inputmarketing">Input Marketing</Link></li><li><Link to="/outputmarketing">Output Marketing</Link></li>
                    </ul>
                </li>
                
                <li class="submenu"><Link to="/csr">CSR Funding/Agencies</Link></li>
                <li class="submenu"><Link to="/apr">APR</Link></li>
                <li class="submenu"><Link to="/contact">Contact Us</Link></li>
            </ul>
        </nav>
    </div>
)




}