import React from "react";


export default function Apmasabout(){


    return(
        <div>
            <p class="aih" style={{fontSize:"30px"}}>About APMAS </p>
            <p  class="act">In 1998, the erstwhile Government of Andhra Pradesh envisaged to set up a Women’s Bank to lend to Self-Help Groups (SHGs) and SHG Federations. The key stakeholders, including Non-Government Organizations (NGOs), Banks, and Micro-finance Institutions (MFIs), who were interested in strengthening the SHG movement felt that the issues at the field level and ensuring quality of SHGs needed priority rather than setting up a lending agency. The Government conducted a series of consultation meetings with state level NGOs engaged in the promotion of SHGs and NABARD. After many rounds of discussions, a working group was constituted, headed by Dr. Vijay Mahajan, which submitted a concept paper in 1998 indicating the need for the establishment of an autonomous state level institution to strengthen the SHGs and provide technical guidance to the institutions involved in promotion and strengthening of SHGs. It was suggested that an autonomous institution be registered as a Public Charitable Trust, with the name Andhra Pradesh Mahila Abhivruddhi Trust. Finally, after considering different options, it was felt that a ‘Society’ would be a better legal form than that of a ‘Trust’ and hence came into being Mahila Abhivruddhi Society, Andhra Pradesh (APMAS). </p>
            <p  class="act">APMAS was registered on 14 June 2001 as a public society under the Andhra Pradesh (Telangana Area) Public Societies Registration Act, 1350 Fasli and subsequently under Foreign Contribution Regulation Act (FCRA) on 28 October 2002. </p>
            <p class="act">APMAS is acting as implementing agency for around 17 FPO’s in Annamayya District and among that Piler Farmers Producers Company limited of the one. It is assisting this FPO from the year 2019 to till date. 

</p>
        </div>
    )
}