import React from "react";
import './pressclips.css';
export default function Pressclippings(){
    
    const hide=()=>{
        let button = document.getElementById('dj');
        let pic = document.getElementById('zm');
        let pisc = document.getElementById('zmk');
        let pis = document.getElementById('zms');
        let pism = document.getElementById('zmp');
    
        if(button.style.display === 'block'){
            button.style.display = 'none';
            pic.style.display = 'flex';
            pisc.style.display = 'flex';
            pis.style.display = 'flex';
            pism.style.display = 'flex';
        }else{
            button.style.display = 'block'
        }
    }
    
    return(
        <>
        <div class="main">
            <div class="hip">
                <h2 class="aim">2024</h2>
                </div>
                <div style={{display:"flex",marginTop:"20px"}} class="kop">
                    <img class="pitr" style={{width:"48%",marginLeft:'-20px'}} src="NPC-2024-1.jpg"/>
                    <img class="pitr" style={{marginLeft:"30px",width:"48%"}} src="NPC-2024-2.jpg"/>
                </div>
           
            <div class="hip" style={{marginTop:"20px"}}>
                <h2 class="aim">2023</h2>
                </div>
                <div style={{display:"flex",marginTop:"20px",marginLeft:"70px"}} class="kop" id="fgh">
                    <img class="pitr" style={{width:"auto"}} src="NPC-2023-1.jpg"/>
                    <img class="pitr" style={{marginLeft:"30px"}} src="NPC-2023-2.jpg"/>
                    <img class="pitr"  style={{marginLeft:"30px"}} src="NPC-2023-3.jpg"/>
                </div>
                <div style={{display:"flex",marginTop:"20px",marginLeft:"230px"}} class="kop">
                    <img class="pitr"style={{width:"48%",marginLeft:"-90px"}} src="NPC-2023-4.jpg"/>
                    <img class="pitr" style={{marginLeft:"30px",width:"48%"}}src="NPC-2023-5.jpg"/>
                </div>
          
            <div class="hip" style={{marginTop:"20px"}}>
                <h2 class="aim">2022</h2>
                </div>
                <div style={{display:"flex",marginTop:"20px"}} class="kop" id="fgh">
                    <img class="pitr" style={{width:"35%"}} src="NPC-2022-1.jpg"/>
                    <img class="pitr" style={{marginLeft:"30px",width:"35%"}} src="NPC-2022-2.jpg"/>
                    <img class="pitr"  style={{marginLeft:"30px",width:"23%"}} src="NPC-2022-3.jpg"/>
                </div>
           
            <div class="hip" style={{marginTop:"20px"}}>
                <h2 class="aim">2021</h2>
                </div>
                <div style={{display:"flex",marginTop:"20px",marginLeft:"150px"}} class="kop">
                    <img class="pitr" style={{width:"40%",marginLeft:"-30px"}} src="NPC-2021-1.jpg"/>
                    <img class="pitr" style={{width:"40%",marginLeft:"50px"}} src="NPC-2021-3.jpg"/>
                </div>
                <div style={{display:"flex",marginTop:"30px"}} class="kop">
                    <img class="pitr" style={{width:"70%",marginLeft:"100px"}} src="NPC-2021-2.jpg"/>
                </div>
            
            <div class="hip" style={{marginTop:"20px"}}>
                <h2 class="aim">2020</h2>
                </div>
                <div style={{display:"flex",marginTop:"20px"}} class="kop">
                    <img class="pitr" style={{width:"90%",marginLeft:"30px"}} src="NPC-2020-1.jpg"/>
                </div>
                <div style={{display:"flex",marginTop:"20px"}} class="kop">
                    <img class="pitr" style={{width:"70%",marginLeft:"30px"}} src="NPC-2020-2.jpg"/>
                </div>
                <div style={{display:"flex",marginTop:"30px"}} class="kop">
                <img class="pitr"  style={{width:"20%"}} src="NPC-2020-3.jpg"/>
                    <img class="pitr" style={{marginLeft:"10px",width:"70%"}} src="NPC-2020-4.jpg"/> 
                </div>
          
            <div class="hip" style={{marginTop:"20px"}}>
                <h2 class="aim">2019</h2>
                </div>
                <div style={{display:"flex",marginTop:"20px"}} class="kop" id="fgh">
                    <img class="pitr" src="NPC-2019-1.jpg"/>
                    <img class="pitr" style={{marginLeft:"30px"}}src="NPC-2019-2.jpg"/>
                    <img class="pitr"  style={{marginLeft:"30px"}}src="NPC-2019-3.jpg"/>
                </div>
                <div style={{display:"flex",marginTop:"30px"}} class="kop" id="fgh">
                    <img class="pitr" src="NPC-2019-4.jpg"/>
                    <img class="pitr" style={{marginLeft:"30px"}}src="NPC-2019-5.jpg"/>
                    <img class="pitr"  style={{marginLeft:"30px"}}src="NPC-2019-6.jpg"/> 
                </div>
                <div style={{display:"flex",marginTop:"30px"}} class="kop">
                    <img class="pitr" style={{width:"49%",marginLeft:"-30px"}} src="NPC-2019-7.jpg"/>
                    <img class="pitr" style={{width:"49%",marginLeft:"30px"}}src="NPC-2019-8.jpg"/>
                </div>
            </div>

        </>
    )
}