import React from "react";
import './bod.css';

export default function Staff(){


    return(
        <div>
            <></>
            
            <img class="bnm" style={{width:"100%"}} src="Project.webp"/>
            
            <h3 class="aih" style={{fontSize:"34px"}}>OUR STAFF</h3>
            <div class="jlo">
            <div class='ctor'>
             <img class="fekkl" style={{marginTop:"-5px"}}src="bharghavi-remove.png"/><br/>
             <h5 class="saha" style={{marginTop:"15px",fontSize:"20px",marginLeft:"20px"}}>Bharghavi-B.Sc.Veterniary  </h5>
             <p class="dent" style={{marginLeft:"50px"}}>Project Manager-Piler </p>
             <p class="dent" style={{marginTop:"7px"}}>Farmers Producers Company, Piler </p>
             </div>
             <div class='cto'>
                         <img class="fek" style={{marginTop:"-5px"}} src="prathy-remove.png"/><br/>
                         <h5  style={{marginLeft:"50px"}}class="saha">Muliki.Prathyusha </h5>
                         <p class="dent" style={{marginLeft:"70px"}}>B.Sc. Horticulture </p>
             <p  class="dent" style={{marginTop:"7px"}}>Farmers Producers Company, Piler  </p>
                         </div>
                         <div class='ct'>
            <img class="fekk" src="pooji-remove.png"/>
            <h5 class="saha" style={{marginTop:"25px",fontSize:"20px",marginLeft:"20px"}}>Poojitha-B.Sc.Life Sciences </h5>
            <p class="dent"  style={{marginLeft:"30px"}}>Accountants & Marketing </p>
             <p class="dent" style={{marginTop:"8px"}}>Farmers Producers Company, Piler 

 </p>
 </div>
            </div>
        </div>
    )
}