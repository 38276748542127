import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import Navbar from './navbar';
import Header from './header';
import Footer from './footer';
import About from './about';
import Apmasabout from './apmasabout';
import Photos from './photos';
import Contact from './contact';
import Service from './service';
import Agiriculture from './agiriculture';
import Horticulture  from './horticulture';
import Boardofdirector from './boardofdirector';
import Bioresourcecenters from './bioresourcecenters';
import Csr from './csr';
import Staff from './staff';
import Videos from './videos';
import Gallery from './gallery';
import Marketing from './marketing';
import Inputmarketing from './inputmarketing';
import Outputmarketing from './outputmarketing';
import Pressclipping from './pressclipping';
import Pressclips19 from './pressclips19';
import Pressclips20 from './pressclips20';
import Pressclips21 from './pressclips21';
import Pressclips22 from './pressclips22';
import Pressclips23 from './pressclips23';
import Pressclips24 from './pressclips24';
import Womenempowerment from './womenempowerment';
import Enterprenuredevelopment from './enterprenuredevelopment';
import { BrowserRouter,Routes,Route } from 'react-router-dom';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
  
    <Navbar />
    <Routes>
      <Route path='/' element={<App/>}></Route>
      <Route path='about' element={<About/>}></Route>
      <Route path='apmasabout' element={<Apmasabout/>}></Route>
      <Route path='contact' element={<Contact/>}></Route>
      <Route path='photos' element={<Photos/>}></Route>
      <Route path='service' element ={<Service/>}></Route>
      <Route path='agiriculture' element ={<Agiriculture/>}></Route>
      <Route path='horticulture' element ={<Horticulture/>}></Route>
      <Route path='boardofdirector' element ={<Boardofdirector/>}></Route>
      <Route path='bioresourcecenters' element ={<Bioresourcecenters/>}></Route>
      <Route path='womenempowerment' element ={<Womenempowerment/>}></Route>
      <Route path='enterprenuredevelopment' element ={<Enterprenuredevelopment/>}></Route>
      <Route path="csr" element={<Csr/>}></Route>
      <Route path="staff" element={<Staff/>}></Route>
      <Route path="videos" element={<Videos/>}></Route>
      <Route path="gallery" element={<Gallery/>}></Route>
      <Route path="marketing" element={<Marketing/>}></Route>
      <Route path='inputmarketing' element={<Inputmarketing/>}></Route>
      <Route path='outputmarketing' element={<Outputmarketing/>}></Route>
      <Route path="pressclipping" element={<Pressclipping/>}></Route>
      <Route path="pressclips19" element={<Pressclips19/>}></Route>
      <Route path="pressclips20" element={<Pressclips20/>}></Route>
      <Route path="pressclips21" element={<Pressclips21/>}></Route>
      <Route path="pressclips22" element={<Pressclips22/>}></Route>
      <Route path="pressclips23" element={<Pressclips23/>}></Route>
      <Route path="pressclips24" element={<Pressclips24/>}></Route>
    </Routes>
    <Footer/>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
