import React from "react";
import './contact.css';
import { FaLocationDot } from "react-icons/fa6";
import { FaPhoneAlt } from "react-icons/fa";
import { MdEmail } from "react-icons/md";

export default function Contact(){


    return(
       <>
       <img style={{width:'100%',height:"300px"}} src="th.jpg"/>

       <div class="gifs">
       
       <div class="d-flex flex-shrink-0 align-items-center justify-content-center rounded-circle bg-white" style={{width:"65px",height:"65px",marginLeft:"70px",paddingTop:"",marginTop:"0px"}}><FaLocationDot class="mza"/></div>
       <p class="cxz">Address</p>
       <p class="zxc"> Piler Farmers Producer Company Limited. Rythu Seva Kendram, Agriculture Marketing Yard Opp. RTC Bus-Stand, Piler-517214, Annammayya Dist. AP</p>
       </div>
       <div class="gifsm">
       <div class="d-flex flex-shrink-0 align-items-center justify-content-center rounded-circle bg-white" style={{width:"65px",height:"65px",marginLeft:"70px",paddingTop:"",marginTop:"0px"}}><FaPhoneAlt  class="mza"/></div>
       <p class="cxz">Call Us Now</p>
       <p class="zxcm">+91 9121022833</p>
       </div>
       <div class="gifsm">
       <div class="d-flex flex-shrink-0 align-items-center justify-content-center rounded-circle bg-white" style={{width:"65px",height:"65px",marginLeft:"70px",paddingTop:"",marginTop:"0px"}}><MdEmail class="mza"/></div>
       <p class="cxz">Mail Us Now</p>
       <p class="zxcm">pilerfpc06122018@gmail.com</p>
       </div>
       <div class="gifsn">
        <p class="fsn">Contact Us</p>
        <h1 class="wqd">Have Any Query? Please Contact Us!</h1>
        <p class="cxzmn">The contact form is currently inactive. Get a functional and working contact form with Ajax & PHP in a few minutes. Just copy and paste the files, add a  little code and you're done. Download Now.</p>
       
    <input type="text" class="form-control" id="names" placeholder="Your Name"/>
               
            <input type="text" class="fomes" placeholder="Your Email"/>
            <input type="text" class="fomesm" placeholder="Subject"/>
                
<textarea rows={6} cols={56}  class="fomesmn" placeholder="Message"></textarea>
               
            <button class="fomesmnp">Send Message</button> 


       </div>
            </>
    )
}