import React from "react";



export default function Horticulture(){


    return(
        <div>
            <p class="act" >Annamayya district is divided into six constituencies. In these six constituencies ( Rayachoti, Pileru, Madanapalle, Thamballapalli, Rajampeta and Koduru). The Majority of the people in the district are depending on HORTICULTUE only. The total horticulture crops cultivating in this district is Acidlime, Banana,  Beans, Beetvine, Bhendi, Bitterguard, Brinjal, Cabbage, Carrot, Chrusanthemum, Coconut, Crossandra, Custered Apple, Muskmelon, Guava, Jack Fruit, Jasmin, Mango, Marigold, Onion, Papaya, Pomegranate, Potato, Pumpkin, Rose,Sapta, Sweet orange, Tamarind, Tomato, Watermon Etc.,one lakh hectors. Piler Farmers Producers company Ltd., had concentrated in the Piler mandal and K.V.Palli Mandal farmers to educate the farmers, introduce new technologies in agriculture and farm mechanization, giving training programs by inviting various sceintiests from various univerties and krishi vigyan kendras of andhrapradesh.
</p>
<p class="act">With the help of APMAS supporting agency and Aurabindo Pharma Foundation Piler FPC had given seeds, seedlings, Fertilizers, Pesticides to the farmers and even procured their products and sold to corporate companies. The profit gained from the business is equally distributed to the FPC members in the form of shares.
</p>
        </div>
    )
}