import React from "react";
import './women.css';



export default function Womenenempowerment(){



    return(
        <div>
           <h1 style={{marginLeft:'50px',marginTop:"20px"}} class="aih">WOMEN EMPOWERMENT </h1>
           <p style={{marginTop:"20px",color:"rgb(66, 65, 65)",paddingTop:"20px",marginRight:"70px",fontSize:"17px",marginLeft:"30px",lineHeightStep:'10px',wordSpacing:"6px"}}>The farmer-producer organisation (FPO) is an umbrella term used to describe modes of farmer collectivisation in India, i.e. co-operatives and companies. While women cultivators play a central role in agriculture, their continued marginalisation is reflected in a lack of engagement in FPO activities and governance structures, with only 3% of existing FPOs being of a female-led ventures.  </p>
       
           <p style={{marginTop:"-20px",color:"rgb(66, 65, 65)",paddingTop:"20px",marginRight:"60px",fontSize:"17px",marginLeft:"30px",lineHeightStep:'10px',wordSpacing:"6px"}}>From 2019 Piler Farmers Producers Company Ltd., had concentrated on women empowerment with the acceptance of the Board Of Members and with a the service agency APMAS and with the financial supporter(Corporate Social Responsibility [CSR])a Aurobindo Pharma Foundation. In Piler  Farmers Producers Company Ltd., among seven members of Board of the Directors we have three women farmers as members.</p>
           <div id="lew">
           <p  id="nts">Women’s empowerment is both intrinsically important and instrumental to achieving agricultural development goals such as Sustainable Development Goal which seeks to increase agricultural productivity among smallholders. Piler Farmers producers company Ltd., focus on gender equality and women’s empowerment, many agricultural development projects now explicitly include women’s empowerment objectives. </p>
           <img  id="wom"src="fy2223-36.jpg"/>
          
           </div>
           <p style={{marginTop:"-20px",color:"rgb(66, 65, 65)",paddingTop:"50px",marginRight:"40px",fontSize:"17px",marginLeft:"30px",lineHeightStep:'10px',wordSpacing:"6px"}}>Interest in women’s participation in agricultural collectives has increased rapidly over the last decade for severals a reasons: Recognition that for smallholder farmers, aggregation is value addition, of meanings acollectives are a pathway to increased incomes; ability to realize  economies of scale in implementation; and the potential to achieve sustainability through building or enhancing collective structures, among others.</p>
        </div>
    )
}