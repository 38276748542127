import React from "react";
import './pressclips.css';
export default function Pressclips(){
    const hide=()=>{
        let button = document.getElementById('dj');
        let pic = document.getElementById('zm');
        let pisc = document.getElementById('zmk');
        let pis = document.getElementById('zms');
        let pism = document.getElementById('zmp');
    
        if(button.style.display === 'block'){
            button.style.display = 'none';
            pic.style.display = 'flex';
            pisc.style.display = 'flex';
            pis.style.display = 'flex';
            pism.style.display = 'flex';
        }else{
            button.style.display = 'block'
        }
    }
    
    return(
        <>
        <div class="main" >
            <div class="hiplk">
                <div class="hip">
                <h2 class="aim">2020</h2>
                </div>
                <div style={{display:"flex" ,marginTop:"60px"}} class="kop">
                    <img class="pitr" style={{width:"90%",marginLeft:"50px"}} src="NPC-2020-1.jpg"/>
                </div>
                <div style={{display:"flex"}} class="kop">
                    <img class="pitr" style={{width:"70%",marginLeft:"130px"}} src="NPC-2020-2.jpg"/>
                </div>
                <div style={{display:"flex"}} class="kop">
                <img class="pitr"  style={{width:"20%"}} src="NPC-2020-3.jpg"/>
                    <img class="pitr" style={{marginLeft:"30px",width:"78%"}} src="NPC-2020-4.jpg"/> 
                </div>
            </div>
        </div>
        </>
    )
}