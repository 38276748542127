import React from "react";
export default function Inputmarketing(){
    return(
        <div class="main">
            <div>
            <h1 class="aih">Input  Marketing</h1>
            </div>
                <b style={{fontSize:"20px",marginLeft:"50px"}}>INPUT MARKETING:</b>
                <p class="incesd">In Piler Farmers Producers Company Ltd., the inputs of seed, pesticides and fertilizers are provided to members for their crops of Paddy, Tomato, Mango, Chilly, Groundnut etc., with minimal percentage in the income. So that this can be benefited to the members of the FPC and even for the maintenance and servility of the FPC staff.</p>
            </div>
        
    )
}