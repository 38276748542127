import React from "react";



export default function Enterprenuredevelopment(){



    return(
        <div>
           <h1 style={{marginLeft:'50px',marginTop:"20px"}} class="aih">Entrepreneur Development   </h1>
           <p style={{marginTop:"20px",color:"rgb(66, 65, 65)",paddingTop:"20px",marginRight:"70px",fontSize:"17px",marginLeft:"30px",lineHeightStep:'10px',wordSpacing:"6px"}}>Thousands of young businesses are now building on India’s agricultural wealth by restoring farmland, pasture, and forests through a range of innovative, cost-effective, and sustainable solutions for farmers. As the impacts of climate change and the availability of non-renewable resources worsen, these restorative businesses will only become more important for India, especially for 86% of its farmers that operate on an average of 0.6 hectares each.a More and more farmers are working  <br/>less land and have little understanding of modern farmings methods and techniques.  developed by the  agri-entrepreneurs. Connecting small and marginal farmers, a growing number of whom are women, and these entrepreneurs can be build a more equitable and sustainable food system for India.</p>
       
           <p style={{marginTop:"-20px",color:"rgb(66, 65, 65)",paddingTop:"20px",marginRight:"71px",fontSize:"17px",marginLeft:"30px",lineHeightStep:'10px',wordSpacing:"6px"}}>At the core of the solution lie Farmer Producer Organisations or Companies. Equipping FPOs or FPCs with entrepreneurial innovations FPOs/ FPCs are government-supported organisations that group farmers – 70-80% of whom are the small and the marginal – and make them more efficient buyers of a  agricultural inputs, and more effective sellers of their products to fresh a  food wholesalers, retailers and large corporates sourcing raw materials. By pooling resources, they  the can also help farmers access new technologies that can improve their yields over the long run. FPOs of  work in every agricultural value chain in India, from a dairies to non-timber forest produce and poultry, and are a key part of the Government of India’s strategy to double farmer incomes, with Rs. 6865 crore now dedicated to promoting 10,000 new FPOs. Adding to the 6,000 that already exist, the a more than 2,200 FPOs are scheduled to be created in 2021, many of which operates in high-value supply chains like organic produce and oilseeds.</p>
           <p style={{marginTop:"-20px",color:"rgb(66, 65, 65)",paddingTop:"20px",marginRight:"71px",fontSize:"17px",marginLeft:"30px",lineHeightStep:'10px',wordSpacing:"6px"}}>With the support of CSR funding with Aurobindo Pharma Foundation and APMAS as services of the agency Piler Farmers Producers Organisation had supported and developed by a many entrepreneurs in the field of agriculture, veterinarys and in processed industries. Few of the entrepreneurs are listed below… CSR (Corporate Social Responsibility)  </p>
           <p style={{marginTop:"-20px",color:"rgb(66, 65, 65)",paddingTop:"20px",marginRight:"40px",fontSize:"17px",marginLeft:"30px",lineHeightStep:'10px',wordSpacing:"6px"}}>Corporate social responsibility (CSR) is a self-regulating business model that helps a company be socially accountable to itself, its stakeholders, and the public.  </p>
           <p style={{marginTop:"-20px",color:"rgb(66, 65, 65)",paddingTop:"20px",marginRight:"40px",fontSize:"17px",marginLeft:"30px",lineHeightStep:'10px',wordSpacing:"6px"}}>By practicing corporate social the responsibility, also called corporate citizenship, companies are aware of how they impact aspects of society, including economic, social,and environmental. Engaging in CSR means a company operates a ways that enhance society and the environment instead of contributing negatively t them. CSR includes four categories:environmental impacts,ethical responsibility, philanthropic endeavours, and financial responsibilities. </p>
           <p style={{marginTop:"-20px",color:"rgb(66, 65, 65)",paddingTop:"20px",marginRight:"40px",fontSize:"17px",marginLeft:"140px",lineHeightStep:'10px',wordSpacing:"6px"}}>Understanding Corporate Social Responsibility (CSR) </p>
           <p style={{marginTop:"-20px",color:"rgb(66, 65, 65)",paddingTop:"20px",marginRight:"40px",fontSize:"17px",marginLeft:"30px",lineHeightStep:'10px',wordSpacing:"6px"}}>Through corporate social a responsibility programs, philanthropy, and volunteer efforts, businesses can benefit society while boosting their brands. A socially responsible company is a accountable to itself and its shareholders. CSR is commonly a strategy employed by large corporations. The more visible and successful a corporation is,  athe more responsibility it has <br/>to a  set standards of ethical behavior for its peers, competition, and industry. </p>
           <p style={{marginTop:"-20px",color:"rgb(66, 65, 65)",paddingTop:"20px",marginRight:"40px",fontSize:"17px",marginLeft:"30px",lineHeightStep:'10px',wordSpacing:"6px"}}>AurobindoPharma Foundation is funding for many NGOs and FPOs across the country. In this connection with help of a APMAS (NGO) our Farmers Producers Company Limited is alsogetting funding from Aurobindo Pharma Foundation for the development of the member farmers of the FPC in various sectors such as Agriculture, Animal Husbandry, women the a  empowerment, entrepreneur development, bio resource centres and many more. </p>
        </div>
    )
}