import React from "react";
import './agiriculture.css';





export default function Agiriculture(){

    return(
        <>
        
        <div class="gep">
              
   <p class="act" id="tcx">Annamayya district is divided into six constituencies. In these six constituencies ( Rayachoti, Pileru, Madanapalle, Thamballapalli, Rajampeta and Koduru). The Majority of the people in the district are depending on Agriculture only. The total agriculture crops cultivating in this district is Paddy, Bajra, Maize, Horse Gram, Red gram, Groundnut and other millets. The area covered under these crops is around 83000 hectors. In piler constituency paddy is grown around 1700 hec and Groundnut is cultivated around 12000 hec.
</p>
<img  id="as" src="1ased.jpeg"/>
<img  id="sed" src="2sed.jpeg"/>
<p id="act">Piler Farmers Producers company Ltd., had concentrated in the Piler mandal and K.V.Palli Mandal farmers to educate the farmers, introduce new technologies in agriculture and farm mechanization, giving training programs by inviting various sceintiests from various univerties and krishi vigyan kendras of andhrapradesh.

</p>
<p id="asm">With the help of APMAS supporting agency and Aurabindo Pharma Foundation Piler FPC had given seeds, Fertilizers, Pesticides to the farmers and even procured their products and sold to corporate companies. The profit gained from the business is equally distributed to the FPC members in the form of shares.
</p>
<img class="bode" src="last before.jpeg"/>
<img id="klq" src="last.jpeg"/>


        </div>
        </>
    )
}