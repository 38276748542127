import React from "react";
import './footer.css';
import { Link } from "react-router-dom";
import { FaLocationDot } from "react-icons/fa6";
import { IoMdMail } from "react-icons/io";
import { FaPhone } from "react-icons/fa";
import { FaFacebook,FaInstagram} from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";
export default function Footer(){



    return(
        <>
             <footer><hr/>
             <div class="foot-address">
                    <p class="heaps"style={{marginLeft:"50px",fontSize:"28px"}}>Find us</p>
                    <FaLocationDot  style={{color:"red",fontSize:"20px"}}/>
<span class="ince">#Piler Farmers Producer Company Limited. Rythu Seva Kendram, Agriculture Marketing Yard Opp. RTC Bus-Stand, Piler-517214,<br/> Annammayya Dist. AP </span><br/>
<IoMdMail style={{color:'green',fontSize:'20px',marginLeft:'8px',marginTop:"25px"}} />
<span style={{marginLeft:"10px",marginTop:"-59px",paddingTop:"-90px",marginBottom:"20px"}}>pilerfpc06122018@gmail.com </span><br/>
<FaPhone style={{color:'',fontSize:'20px',marginLeft:'8px',marginTop:"15px"}}/>
<span>+91 9121022833 </span>
                
                </div>
            <div class="usefullink">
                <p class="heaps"style={{marginLeft:"90px"}}>Useful links:</p>
                <div class="topl" style={{marginLeft:"150px"}}>
                      <li><Link to="" stytle={{marginTop:'-20px'}} class="buttlo">Home</Link></li> 
                       <li><Link to="/about" class="buttlo" >About Us</Link></li>
                       <li><Link to="/contact" class="buttlo" >Content Us</Link></li>
                       <li><Link to="/gallery" class="buttlo">Gallery</Link></li>
                       <li><Link to="/service" class="buttlo">Service</Link></li>
                       <li><Link to="/pressclipping" class="buttlo">NewsPapper Clipping </Link></li>
                       <li><Link to="/marketing" class="buttlo">Marketing</Link></li>
                       <li><Link to="/testimonal" class="buttlo">Testimonal</Link></li>
                       <li><Link to="/csr" class="buttlo">CSR Fundfing</Link></li>
                     
                       </div>
            </div>
           
            <div class="navigate-map">
                <p class="and">Google Map</p><br/>
                    <iframe class="map-place" src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d1302.9150900966904!2d78.94525946728594!3d13.655772935544896!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e1!3m2!1sen!2sin!4v1715783383206!5m2!1sen!2sin" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                </div>
               
                <div class="social-media-links">
                   
                    <a href=" https://www.facebook.com/profile.php?id=61560041463490&mibextid=ZbWKwL" target='blank' class="qv" style={{fontSize:'30px', color:'black'}}><FaFacebook /></a>&emsp;
            <a href='https://www.instagram.com/pilerfpc?igsh=Zjl4YXQ0bjlkNXh0' target='blank' style={{fontSize:'30px',color:'black',marginLeft:"20px"}}><FaInstagram /></a>&emsp;
            <a href='https://www.youtube.com/channel/UCufatYJy4MFnI90vsuvTjMg' target='blank' style={{fontSize:'30px',color:'black', marginLeft:"20px"}}><FaYoutube /></a>&nbsp;
                </div>
             
                <div  class="va" >
                <span >Prepared By :- </span>
                <span >ANUSANDHAAN YUVA..</span>
            </div>
            <hr/>
           
            <div class="foot-div-bottom">
                <p>&copy;<a class="uselinks" href="pfpc">Piler Farmers Producers Company ltd.</a></p>

                
            </div>
        </footer>
        </>
    )
}