import React from "react";
import './pressclips.css';
export default function Pressclips24(){
    const hide=()=>{
        let button = document.getElementById('dj');
        let pic = document.getElementById('zm');
        let pisc = document.getElementById('zmk');
        let pis = document.getElementById('zms');
        let pism = document.getElementById('zmp');
    
        if(button.style.display === 'block'){
            button.style.display = 'none';
            pic.style.display = 'flex';
            pisc.style.display = 'flex';
            pis.style.display = 'flex';
            pism.style.display = 'flex';
        }else{
            button.style.display = 'block'
        }
    }
    
    return(
        <>
        <div class="main">
            <div class="hip">
               
                <h2 class="aim">2024</h2>
                </div>
                <div style={{display:"flex",marginTop:"60px"}} class="kop">
                    <img class="pitr" style={{width:"48%"}} src="NPC-2024-1.jpg"/>
                    <img class="pitr" style={{marginLeft:"30px",width:"48%"}} src="NPC-2024-2.jpg"/>
                </div>
            </div>
        
        </>
    )
}