import React from "react";
export default function Outputmarketing(){
    return(
        <div class="main">
             <div >
            <h1 class="aih">Output Marketing</h1>
           </div>
                <b style={{fontSize:"20px",marginLeft:"50px"}}>OUTPUT MARKETING:</b>
                <p class="incesd">Farmer producer organisations (FPOs) are collectives of farmers formed to leverage the benefit of economies of scale and a better connect to market. The purpose of FPOs is to offer input and output services to members and to act as aggregator. FPOs can negotiate with institutional buyers to leverage better prices for its farmer members.</p>
                <p class="incesd"> With the help of CSR funding with Aurobindo Pharma Foundation Piler Farmers Producers Company Ltd., is doing output marketing.There are around 770 farmers in piler farmers producers company ltd., as share holders and these farmers are in lack of marketing their produced products. The middle man is gaining much more than the farmers by selling their cultivated products. </p>
                <p class="incesd">So piler FPC Board of Directors had decided to do out put marketing of the agricultural products cultivated by piler FPC farmers. BODs had contacted many local, national and corporate company to procure the products without middle man. So that, there won’t be middle man, suit and even commission. So that farmer will be benefited by selling their product directly.Here are the some of the business done by the Piler Farmers Producers Company Ltd., from past 2019.</p>
            </div>
       
    )
}