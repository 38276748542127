export const SliderData = [
    {
      image:
        'Slide-img-1.jpg'
    },
    {
      image:
        'Slide-img-2.jpg'
    },
    {
      image:
        'Slide-img-3.jpg'
    },
    {
      image:
        'Slide-img-4.jpg'
    },
    {
      image:
        'Slide-img-5.jpg'

    },
    {
      image:'Slide-img-6.jpg'
    }
  ];
  