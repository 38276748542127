// import React from "react";
// import './bod.css';

// export default function Boardofdirector(){


//     return(
//         <div>
//             <></>
            
//             <img class="bnm" style={{width:"100%"}} src="Project.webp"/>
            
//             <h3 class="aih" style={{fontSize:"34px"}}>Board of Directors</h3>
//             <div class="jlo">
//             <div class='ctor'>
//              <img class="fekkl" src="felloe-remove.png"/><br/>
//              <h5 class="saha">C. Sahadev Reddy </h5>
//              <p class="dent">President Piler FPC </p>
//              </div>
//              <div class='cto'>
//                          <img class="fek" src="dire-remove.png"/><br/>
//                          <h5  style={{marginLeft:"130px"}}class="saha">Sridevi </h5>
//              <p  class="dent">Board of Director Piler FPC  </p>
//                          </div>
//                          <div class='ct'>
//             <img class="fekk" src="bodesh-remov.png"/>
//             <h5 class="saha" style={{marginTop:"3px"}}>K. S. Bodeesha Vali </h5>
//             <p style={{marginLeft:"90px",marginTop:"-36px"}}>M.Sc., M.B.A., B.Ed., </p>
//              <p class="dent" >Board of Director Piler FPC 

//  </p></div>
//             </div>
//         </div>
//     )
// }
import React from "react";
import './bod.css';

export default function Boardofdirector(){

    return(
        <div>
            <></>
            
            <img class="bnm" style={{width:"100%"}} src="Project.webp"/>
            
            <h3 class="aih" style={{fontSize:"5vw"}}>Board of Directors</h3>
            <div class="jlo">
                <div class='ctor'>
                    <img class="fekkl" src="felloe-remove.png"/><br/>
                    <h5 class="saha" style={{marginLeft:"40px"}}>C. Sahadev Reddy </h5>
                    <p class="dent">President Piler FPC </p>
                </div>
                <div class='cto'>
                    <img class="fek" src="dire-remove.png"/><br/>
                    <h5 class="saha">Sridevi </h5>
                    <p class="dent">Board of Director Piler FPC  </p>
                </div>
                <div class='ct'>
                    <img class="fekk" src="bodesh-remov.png"/>
                    <h5 class="saha" style={{marginLeft:"50px"}}>K. S. Bodeesha Vali </h5>
                    <p class="dent" style={{marginLeft:"90px",marginTop:"-40px"}}>M.Sc., M.B.A., B.Ed., </p>
                    <p class="dent">Board of Director Piler FPC</p>
                </div>
            </div>
        </div>
    )
}
