import React from "react";
import './csr.css';
export default function Csr(){
    return(
        <div class="main">
          
            <h2 class="aih">CSR (Corporate Social Responsibility)</h2>
       
           
                <p class="incesd"><b style={{fontSize:"25px"}}>Corporate social responsibility (CSR)</b> is a self-regulating business model that helps a company be socially accountable to itself, its stakeholders, and the public. </p>
                <p class="incesd">By practicing corporate social responsibility, also called corporate citizenship, companies are aware of how they impact aspects of society, including economic, social, and environmental. Engaging in CSR means a company operates in ways that enhance society and the environment instead of contributing negatively to them. CSR includes four categories: environmental impacts, ethical responsibility, philanthropic endeavours, and financial responsibilities.</p>
                <p class="incesd"><b>Understanding Corporate Social Responsibility (CSR)</b><br/><br/>Through corporate social responsibility programs, philanthropy, and volunteer efforts, businesses can benefit society while boosting their brands. A socially responsible company is accountable to itself and its shareholders. CSR is commonly a strategy employed by large corporations. The more visible and successful a corporation is, the more responsibility it has to set standards of ethical behavior for its peers, competition, and industry.</p>
                <p class="incesd">Aurobindo Pharma Foundation is funding for many NGOs and FPOs across the country. In this connection with help of APMAS (NGO) our Farmers Producers Company Limited is also getting funding from Aurobindo Pharma Foundation for the development of the member farmers of the FPC in various sectors such as Agriculture, Animal Husbandry, women empowerment, entrepreneur development, bio resource centres and many more.</p>
            </div>
        
    )
}